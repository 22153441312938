//---Validation---
let validate = Array.from(document.getElementsByClassName('validate'))
const XHRRequest = new XMLHttpRequest();
let firstNameCopy,phoneCopy

export function checkForm(token) {
    const modal = document.getElementById("myModal");
    const [modalContent] = document.getElementsByClassName("modal-content");

    let firstName = document.getElementById("name").value;
    let phone = document.getElementById("phone").value;
    let from = document.getElementById("from").value;
    let to = document.getElementById("to").value;
    let captcha = document.getElementById("g-recaptcha-response")

    const entry = document.getElementById("entry");
    const departure = document.getElementById("departure");
    const confirm_name = document.getElementById("confirm-name");
    const confirm_phone = document.getElementById("confirm-phone");

    const [first_input] = document.getElementsByClassName('form__item-one')
    const [second_input] = document.getElementsByClassName('form__item-two')
    const [third_input] = document.getElementsByClassName('form__item-three')
    const [four_input] = document.getElementsByClassName('form__item-four')
    const [five_input] = document.getElementsByClassName('form__item-five')

    const price = document.getElementById("out").innerHTML;

    let [confirmed] = document.getElementsByClassName("modal__confirmed")

    let phoneCanSubmit, nameCanSubmit, priceCanSubmit, validCaptcha = false;

    function checkFirstName() {
        if (firstName.length < 3 || firstName.length > 10 || !isNaN(firstName)) {
            nameCanSubmit = false;
            document.getElementById('val_name').innerHTML = 'Имя должно содержать от 3 до 10 символов'
            third_input.classList.add('custom-input--warning')
        } else {
            nameCanSubmit = true;
            firstNameCopy = firstName
        }

    }
    checkFirstName();

    function checkPhoneNumber() {
        if (!phone.match(/^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/)) {
            phoneCanSubmit = false;
            document.getElementById('val_phone').innerHTML = 'Введите правильный телефон'
            four_input.classList.add('custom-input--warning')
        } else {
            phoneCanSubmit = true;
            phone = phone.replace(/[^0-9]/g, '')
            phone ='+7' + phone.substring(1, phone.length)
            phoneCopy = phone
        }
    }
    checkPhoneNumber();

    function checkPrice() {
        if (price === "0 ₽") {
            priceCanSubmit = false
            document.getElementById('val_date').innerHTML = 'Введите корректную дату'
            first_input.classList.add('custom-input--warning')
            second_input.classList.add('custom-input--warning')
        } else {
            priceCanSubmit = true
        }
    }
    checkPrice()

    function checkCaptcha () {
        const captchaStr = token
        if (!captchaStr) {
            validCaptcha = false
        } else {
            validCaptcha = captchaStr
        }
    }
    checkCaptcha()


    if (phoneCanSubmit && nameCanSubmit && priceCanSubmit && validCaptcha) {
        document.getElementById("submit").setAttribute('disabled', 'disabled')
        XHRRequest.open('POST', 'https://uletpark.ru/api/book', true)
        XHRRequest.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        XHRRequest.setRequestHeader('Content-Type', 'application/json')

        XHRRequest.send(JSON.stringify({
            from: from,
            to: to,
            name: firstName,
            phone: phone,
            captcha: validCaptcha
        }))

        XHRRequest.onload = function(e) {
            if (XHRRequest.status != 200) { // HTTP ошибка?
                document.getElementById("submit").removeAttribute('disabled')
                return;
            }
            document.getElementById("name").value = ''
            document.getElementById("phone").value = ''
            document.getElementById("from").value = new Date().toISOString().slice(0, 16)
            document.getElementById("to").value = new Date(Date.now() + 48 * 60 * 60 * 1000).toISOString().slice(0, 16)
            captcha = ''
            document.getElementById("submit").removeAttribute('disabled')
            document.getElementsByClassName("map-wrapper")[0].style.display = "none"
            modal.style.visibility = "visible"
            confirmed.style.display = 'block'
            modalContent.classList.add('confirmed-modal')
            entry.innerHTML = new Date(from).toLocaleString()
            departure.innerHTML = new Date(to).toLocaleString()
            confirm_name.innerHTML = firstNameCopy
            confirm_phone.innerHTML = phoneCopy
        };

        XHRRequest.onerror = function() {
            // обработаем ошибку, не связанную с HTTP (например, нет соединения)
            document.getElementById("submit").removeAttribute('disabled')
        };
    }
}

Array.from(document.getElementsByTagName('input')).forEach(item => {
    item.addEventListener('change',() => {
        validate.forEach(el => {
            el.innerHTML = ''
        })
        document.getElementsByClassName('form__item-one')[0].classList.remove('custom-input--warning')
        document.getElementsByClassName('form__item-two')[0].classList.remove('custom-input--warning')
        document.getElementsByClassName('form__item-three')[0].classList.remove('custom-input--warning')
        document.getElementsByClassName('form__item-four')[0].classList.remove('custom-input--warning')
    })
})

// document.getElementById("submit").onclick = checkForm